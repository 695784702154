.page-services-single {
  .content {
    > p {
      &:first-of-type {
        font-size: 1.6rem;
        line-height: 1.4;
        margin-bottom: 40px;
        font-weight: regular;
        color: rgb(104, 104, 104);
      }
    }
  }
}
